._failed {
    border-bottom: solid 4px red !important;
  }
  
  ._failed i {
    color: red !important;
  }
  
  ._success {
    box-shadow: 0 15px 25px #00000019;
    padding: 45px;
    width: 100%;
    text-align: center;
    margin: 40px auto;
    border-bottom: solid 4px #28a745;
  }
  
  ._success i {
    font-size: 55px;
    color: #28a745;
  }
  
  ._success h2 {
    margin-bottom: 12px;
    font-size: 40px;
    font-weight: 500;
    line-height: 1.2;
    margin-top: 10px;
  }
  
  ._success p {
    margin-bottom: 0px;
    font-size: 18px;
    color: #495057;
    font-weight: 500;
  }
  
  ._failed {
    box-shadow: 0 15px 25px #00000019;
    padding: 45px;
    width: 100%;
    text-align: center;
    margin: 40px auto;
  }
  
  ._failed i {
    font-size: 55px;
    color: red;
  }
  
  ._failed h2 {
    margin-bottom: 12px;
    font-size: 20px;
    font-weight: 700;
    line-height: 1.2;
    margin-top: 10px;
  }
  
  ._failed p {
    margin-bottom: 0px;
    font-size: 18px;
    color: #495057;
    font-weight: 500;
  }
  .message-box._success {
    margin-top: 130px;
 
  }
  
  .message-box._failed {
    margin-top: 130px;
  }